import { Button, Card, Heading, Stack } from '@chakra-ui/react';
import { getSystemStatus, RobotStatus } from '../../../interfaces/system';
import { useStore } from '../../../store';
import { toggleEcoMode } from '../../../utils/pasta';

export function EcoMode() {
  const {
    store: { formantDevice, pastaSystem },
  } = useStore();

  const isEcoModeStatusActivated =
    pastaSystem?.status === getSystemStatus(RobotStatus.ECO);

  const handleToggleEcoMode = () => {
    if (!formantDevice) return;
    const batch = {
      eco_mode_status: !isEcoModeStatusActivated,
    };
    toggleEcoMode(formantDevice, batch);
  };
  return (
    <Card p="3">
      <Stack spacing={4}>
        <Heading as="h4" size="md" className="component-heading-small">
          Mode Éco {isEcoModeStatusActivated ? 'Activé' : 'Désactivé'}
        </Heading>
        <Button
          fontSize="2xl"
          size="lg"
          colorScheme="blue"
          onClick={() => handleToggleEcoMode()}>
          {isEcoModeStatusActivated ? 'Désactiver' : 'Activer'} Mode Éco
        </Button>
      </Stack>
    </Card>
  );
}
