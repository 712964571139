import {
  Box,
  Card,
  CardBody,
  Grid,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import StatusBadge from '../../StatusBadge';
import { useStore } from '../../../store';
import { AutoCooking } from './AutoCooking';
import { BatchCooking } from './BatchCooking';
import { EcoMode } from './EcoMode';

export function PastaSystem() {
  const {
    store: { pastaSystem },
  } = useStore();

  return (
    <Card bg="white" p={4} mb={4}>
      <Grid templateColumns="1fr 1fr" gap={4}>
        <Box p={4}>
          <Stack spacing={4}>
            <Heading
              as="h3"
              size="lg"
              className="component-heading-small"
              mb={2}>
              Pasta
            </Heading>
            <StatusBadge status={pastaSystem?.status} />

            <Card>
              <CardBody>
                <Box>
                  <Grid templateColumns="1fr 1fr" gap={4} alignItems="center">
                    <Text fontWeight="bold" fontSize="2xl" textAlign="center">
                      Pates en cuisson
                    </Text>
                    <Text fontWeight="bold" fontSize="4xl" textAlign="center">
                      {pastaSystem?.currentDishesCooking}
                    </Text>
                  </Grid>
                </Box>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Box>
                  <Text fontWeight="bold" fontSize="m" textAlign="center">
                    Temperature (min 85°C pour cuire des pâtes)
                  </Text>
                  <Text fontWeight="bold" fontSize="4xl" textAlign="center">
                    {pastaSystem?.rightPastaContainer?.temperature}
                    °C
                  </Text>
                </Box>
              </CardBody>
            </Card>
          </Stack>
        </Box>
        <Box>
          <Stack spacing={3.5}>
            <AutoCooking />
            <BatchCooking />
            <EcoMode />
          </Stack>
        </Box>
      </Grid>
    </Card>
  );
}
