/* eslint-disable @typescript-eslint/no-explicit-any */
import { Device } from '@formant/data-sdk';

// import { IMainDispatch } from '../store/reducers';
// import { IMainStore } from '../store/stores';
import {
  StartSaucesCleaningParameters,
  UpdateContainerStatusParameters,
  UpdateSaucesRefillParameters,
  UpdateSauceLevelsParameters,
} from '../interfaces/sauce';
import {
  RebootPastaSystemParameters,
  StartAutoCookerParameters,
  UpdateAutoCookerPastaFrequencyParameters,
  UpdateAutoCookerStatusParameters,
  BatchSizeParameters,
  EcoModeParameters,
} from '../interfaces/pasta';
import { UpdateToppingsStatusParameters } from '../interfaces/topping';
import { AnswerNotificationParameters } from '../interfaces/system';

export type CommandParameters =
  | UpdateContainerStatusParameters
  | UpdateSaucesRefillParameters
  | StartSaucesCleaningParameters
  | RebootPastaSystemParameters
  | UpdateAutoCookerPastaFrequencyParameters
  | UpdateAutoCookerStatusParameters
  | StartAutoCookerParameters
  | UpdateToppingsStatusParameters
  | BatchSizeParameters
  | AnswerNotificationParameters
  | UpdateSauceLevelsParameters
  | EcoModeParameters;

export const sendCommandToFormant = async (
  device: Device,
  commandName: string,
  parameters?: CommandParameters,
) => {
  try {
    if (device) {
      if (parameters === undefined) {
        await device.sendCommand(commandName);
      } else {
        const stringifiedParameters = JSON.stringify(parameters);
        await device.sendCommand(commandName, stringifiedParameters);
      }
    }
  } catch (e) {
    console.log('Error in sendCommandToFormant', e);
  }
};

export const sendMsgToRobotViaChannel = async (channel: any, message: any) => {
  try {
    channel.send(
      JSON.stringify({
        value: JSON.stringify(message),
      }),
    );
  } catch (e: any) {
    console.log(e.message);
  }
};

// export const startChannelListener = async (
//   dispatch: (value: IMainDispatch) => void,
//   channel: any,
//   store: IMainStore,
// ) => {
//   try {
//     channel.addListener((message: any) => {
//       const messagaJson = JSON.parse(message);
//       return messagaJson;
//     });
//   } catch (e) {
//     // console.log(e.message);
//     return null;
//   }
//   return null;
// };

// export const createCustomChannel = async (device: any, channelName: string) => {
//   await device.startRealtimeConnection();
//   const channel = await device.createCustomDataChannel(channelName);
//   return channel;
// };
